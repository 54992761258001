import React from 'react';
import styled from 'styled-components';

const ArrowWrapper = styled.svg`
  fill: ${props => props.color};
  ${props =>
    props.reverse &&
    `
    transform: scaleX(-1);
  `}
`;

const Arrow = ({
  color = 'var(--c-blue)',
  width = 48,
  height = 27,
  reverse,
}) => (
  <ArrowWrapper
    reverse={reverse}
    width={width}
    height={height}
    color={color}
    viewBox="0 0 48 27"
  >
    <polygon points="24.175 27 48 13.605 48 13.395 24.175 0 24.035 .349 40.082 12.907 0 12.558 0 14.651 39.942 14.233 24.035 26.651" />
  </ArrowWrapper>
);

export default Arrow;
