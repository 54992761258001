import React from 'react';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import styled, { ThemeProvider } from 'styled-components';

import { Grid } from './grid-system';

import Header from './header';
import Footer from './footer';

import bp from '../assets/js/breakpoints';
import MigraVF from '../assets/fonts/migra/Migra-Variable.ttf';
import LibreFranklinLight from '../assets/fonts/libre-franklin/LibreFranklin-Light.ttf';
import LibreFranklinLightItalic from '../assets/fonts/libre-franklin/LibreFranklin-LightItalic.ttf';
import LibreFranklinBold from '../assets/fonts/libre-franklin/LibreFranklin-Bold.ttf';
import LibreFranklinBoldItalic from '../assets/fonts/libre-franklin/LibreFranklin-BoldItalic.ttf';
import EyePattern from './eye-pattern';
import Noise from './noise';

// Setup initial grid settings
const theme = {
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 2.4, // rem
    outerMarginMobile: 2.4, // rem
    outerMarginDesktop: 4.8, // rem
    mediaQuery: 'only screen',
    breakpoints: {
      base: 0,
      xs: bp.breakpoints.xs, // em
      xs_sm: bp.breakpoints.xs_sm, // em
      sm: bp.breakpoints.sm, // em
      sm_md: bp.breakpoints.sm_md, // em
      md: bp.breakpoints.md, // em
      lg: bp.breakpoints.lg, // em
      xl: bp.breakpoints.xl, // em
    },
  },
};

const GlobalStyle = createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'MigraVF';
    src: url(${MigraVF}) format('truetype-variations');
    font-weight: 100 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Libre Franklin';
    src: url(${LibreFranklinLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Libre Franklin';
    src: url(${LibreFranklinLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Libre Franklin';
    src: url(${LibreFranklinBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Libre Franklin';
    src: url(${LibreFranklinBoldItalic}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }

  :root {
    font-size: 62.5%;

    --c-black: #14171c;
    --c-white: #f0e9df;
    --c-gray: #99a6ad;
    --c-blue: #87d7de;
    --c-purple: #717ce0;
    --c-orange: #ff956d;

    --f-serif: 'MigraVF', serif;
    --f-sans: 'Libre Franklin', Helvetica, sans-serif;
    --f-extra-light: 200;
    --f-light: 300;
    --f-medium: 600;
    --f-bold: 700;

    --f-size-mobile: 2.0rem;
    --f-size-desktop: 2.4rem;

    --width-max: 168rem;

    --spacing: .8rem;
    --space-xxxs: calc(var(--spacing) * 0.5);
    --space-xxs: calc(var(--spacing) * 1);
    --space-xs: calc(var(--spacing) * 1.5);
    --space-s: calc(var(--spacing) * 2);
    --space-m: calc(var(--spacing) * 3);
    --space-l: calc(var(--spacing) * 4);
    --space-xl: calc(var(--spacing) * 5);
    --space-xxl: calc(var(--spacing) * 9);
    --space-xxxl: calc(var(--spacing) * 18);

    --easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
    --easeInOutExpo: cubic-bezier(1, 0, 0, 1);
    --easeInOutBounce: cubic-bezier(0.8, -0.15, 0.4, 1.75);
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    min-height: 100%;
    width: 100%;
    margin: 0;
  }

  body {
    background-color: var(--c-black);
    font-family: var(--f-serif);
    color: var(--c-white);
    font-size: var(--f-size-mobile);
    font-variation-settings: 'wght' var(--f-extra-light);
    line-height: 3.2rem;
    letter-spacing: .02rem;
    text-align: left;
    transition: all 300ms ease-in-out;
    overflow-x: hidden;

    font-variant-numeric: lining-nums;
    font-feature-settings: 'lnum';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (${bp.min.xs_sm}) {
      font-size: var(--f-size-desktop);
      line-height: 3.6rem;
    }
  }

  a {
    color: var(--c-blue);
    transition: all 250ms var(--easeInOutExpo);
    text-decoration: none;

    &:hover {
      color: var(--c-white);
    }
  }

  button {
    appearance: none;
    border: none;
    background: transparent;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
  }

  .carousel,
  .flickity-viewport {
    &:focus {
      outline: none;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: var(--width-max);
  margin: 0 auto;
  overflow-x: hidden;
`;

const Layout = ({ children, isHomepage, is404 }) => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Wrapper>
          <MainContainer>
            <Grid fluid>
              {!is404 && <Header isHomepage={isHomepage} />}

              <main>{children}</main>
            </Grid>

            {isHomepage && <EyePattern />}
            {!is404 && <Footer />}
          </MainContainer>

          <Noise />
        </Wrapper>
      </ThemeProvider>
    </>
  );
};

export default Layout;
