import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Arrow from './arrow';
import bp from '../assets/js/breakpoints';

// TODO: Update button to be a link or an a tag (look at how this was done on ?????????)

const Wrapper = styled.a`
  font-size: 2.4rem;
  color: var(--c-white);

  ${props =>
    props.reversed &&
    `
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
  `}

  @media (${bp.min.xs_sm}) {
    font-size: 2.8rem;
  }

  svg {
    margin-left: var(--space-xs);
    transition: all 450ms var(--easeInOutBounce);

    ${props =>
      props.reversed &&
      `
      transform: scaleX(-1);
      margin-left: 0;
      margin-right: var(--space-xs);
      margin-bottom: var(--space-xxxs);
    `}
  }

  &:hover {
    color: var(--c-blue);

    svg {
      transform: translateX(0.4rem);

      ${props =>
        props.reversed &&
        `
          transform: translateX(-0.4rem) scaleX(-1);
      `}
    }
  }
`;

const ButtonWrapper = ({ children, target, url, reversed }) => {
  if (!url) {
    return (
      <Wrapper as="button" reversed={reversed}>
        {children}
      </Wrapper>
    );
  } else {
    if (target === '_blank') {
      return (
        <Wrapper
          href={url}
          target={target}
          rel="noreferrer"
          reversed={reversed}
        >
          {children}
        </Wrapper>
      );
    } else {
      return (
        <Wrapper as={Link} to={url} reversed={reversed}>
          {children}
        </Wrapper>
      );
    }
  }
};

const Button = ({ text = 'Button', withArrow, url, target, reversed }) => (
  <ButtonWrapper url={url} target={target} rel="noreferrer" reversed={reversed}>
    {text}
    {withArrow && <Arrow width={24} height={16} />}
  </ButtonWrapper>
);

export default Button;
