import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Button from './button';
import gsap from 'gsap';

import bp from '../assets/js/breakpoints';

import EyeLogo from './eye-logo';
import { Link } from 'gatsby';

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: ${props =>
    props.isHomepage ? 'flex-end' : 'space-between'};
  align-items: center;
  padding-top: var(--space-m);
  margin-bottom: ${props => !props.isHomepage && 'var(--space-xxl)'};
  visibility: hidden;

  @media (${bp.min.xs_sm}) {
    padding-top: var(--space-xl);
    margin-bottom: ${props => !props.isHomepage && 'var(--space-xxxl)'};
  }
`;

const Header = ({ isHomepage }) => {
  const header = useRef(null);

  useEffect(() => {
    gsap.from(header.current, {
      duration: 1.1,
      autoAlpha: 0,
      ease: 'power2.inOut',
      delay: isHomepage ? 1.5 : 0.2,
    });
  }, [isHomepage]);

  return (
    <HeaderWrapper ref={header} isHomepage={isHomepage}>
      {!isHomepage && (
        <Link to="/">
          <EyeLogo width={72} />
        </Link>
      )}

      <Button
        text="Get in touch"
        url="mailto:hello@universalpatterns.com"
        target="_blank"
        withArrow
      />
    </HeaderWrapper>
  );
};

export default Header;
