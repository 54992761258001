import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import noise from '../images/noise.png';

const NoiseBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${noise});
  background-repeat: repeat;
  background-size: 25%;
  opacity: 0.75;
  pointer-events: none;
  visibility: hidden;
  z-index: 10;
`;

const Noise = () => {
  const noise = useRef(null);

  useEffect(() => {
    gsap.from(noise.current, {
      duration: 1,
      delay: 1,
      autoAlpha: 0,
    });
  }, []);

  return <NoiseBG ref={noise} />;
};

export default Noise;
